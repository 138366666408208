import { NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'hb-add-field-modal',
  templateUrl: './add-field-modal.component.html',
  styleUrls: ['./add-field-modal.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    MatSlideToggleModule,
    FormsModule,
    NgIf,
    MatTooltipModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule
  ]
})
export class AddFieldModalComponent implements OnInit {
  public createFieldForm: FormGroup;
  public displayAdrressInput: boolean;
  public displayIfInside = true;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddFieldModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storageService: StorageService
  ) {}
  
  public get contactControls(): FormArray<any> {
    return this.createFieldForm.get('contacts') as FormArray;
  }

  public ngOnInit(): void {
    const city = this.storageService.getValue('store-city');
    this.createFieldForm = this.formBuilder.group({
      title: [this.data.title],
      city: [{ value: city, disabled: true }],
      description: [],
      address: [],
      long: [],
      lat: [],
      contacts: this.formBuilder.array([])
    });
  }

  public createContact(): FormGroup {
    return this.formBuilder.group({
      label: [''],
      contact: ['']
    });
  }

  public addContact(): void {
    this.contactControls.push(this.createContact());
  }

  public removeContact(index: number): void {
    this.contactControls.removeAt(index);
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public submitForm(): void {
    const newField = { ...this.createFieldForm.getRawValue() };
    newField.isIndoor = this.displayIfInside;
    this.dialogRef.close(newField);
  }

  public toggle(event: any): void {
    this.displayAdrressInput = event.checked;
  }
}

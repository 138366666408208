<header class="top-bar flex-row justify-center" role="banner">
    <button class="close-icon cursor-pointer" (click)="onClose()" aria-label="Close">
        <mat-icon>close</mat-icon>
    </button>
    <h1 class="page-label" i18n>Add Field</h1>
</header>
<section [formGroup]="createFieldForm" class="create-form-field flex-col gap-25">
    <mat-form-field appearance="fill" class="custom-mat-form-field" name="title">
        <mat-label class="mat-label" i18n>Select Title</mat-label>
        <input matInput panelClass="select-panel" formControlName="title">
    </mat-form-field>
    <mat-form-field appearance="fill" class="custom-mat-form-field" name="city">
        <mat-label class="mat-label" i18n>Select City</mat-label>
        <input matInput panelClass="select-panel" formControlName="city" i18n-matTooltip
            matTooltip="You can change the city in the profile settings" [matTooltipPosition]="'below'"
            #tooltip="matTooltip">
    </mat-form-field>
    <mat-form-field appearance="fill" class="description-mat-form-field">
        <mat-label class="description-text" i18n>Description</mat-label>
        <textarea matInput class="desciption-area" rows="4" maxlength="555" formControlName="description"></textarea>
    </mat-form-field>
    <div class="toggle-switch flex-row">
        <mat-slide-toggle color="primary" labelPosition="before" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="displayAdrressInput" (change)="toggle($event)">
            <ng-container *ngIf="displayAdrressInput" i18n>Long/Lat</ng-container>
            <ng-container *ngIf="!displayAdrressInput" i18n>Adress</ng-container>
        </mat-slide-toggle>
    </div>
    <!-- ToDo: change lat, long logic input -->
    <div class="flex-row gap-10" *ngIf="displayAdrressInput">
        <mat-form-field appearance="fill" class="custom-mat-form-field">
            <mat-label class="mat-label" i18n>Long</mat-label>
            <input matInput formControlName="long" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="custom-mat-form-field">
            <mat-label class="mat-label" i18n>Lat</mat-label>
            <input matInput formControlName="lat" />
        </mat-form-field>
    </div>
    <mat-form-field *ngIf="!displayAdrressInput" appearance="fill" class="custom-mat-form-field">
        <mat-label class="mat-label" i18n>Adrress</mat-label>
        <input matInput formControlName="address" />
    </mat-form-field>
    <div class="toggle-switch flex-row">
        <mat-slide-toggle color="primary" labelPosition="before" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="displayIfInside">
            <ng-container *ngIf="displayIfInside" i18n>Inside</ng-container>
            <ng-container *ngIf="!displayIfInside" i18n>Outside</ng-container>
        </mat-slide-toggle>
    </div>
    <div class="flex-row space-between align-center">
        <h2 class="link-title" i18n>Contacts</h2>
        <button class="va-middle add-button" (click)="addContact()" aria-label="Click here to add contact">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <div formArrayName="contacts" *ngFor="let contact of contactControls.controls; let i = index">
        <div [formGroupName]="i" class="flex-row gap-10">
            <mat-form-field appearance="fill" class="custom-mat-form-field">
                <mat-label class="mat-label" i18n>Contact Label</mat-label>
                <input matInput formControlName="label" />
            </mat-form-field>
            <mat-form-field appearance="fill" class="custom-mat-form-field">
                <mat-label class="mat-label" i18n>Contact Value</mat-label>
                <input matInput formControlName="contact" />
            </mat-form-field>
            <button class="va-middle remove-button" (click)="removeContact(i)"
                aria-label="Click here to remove contact">
                <mat-icon>remove</mat-icon>
            </button>
        </div>
    </div>
</section>
<footer class="submit-buttons flex-col gap-10">
    <button class="fill-button" mat-raised-button (click)="submitForm()" aria-label="Click here to submit form"
        i18n-aria-label>Submit</button>
    <button class="outline-button" mat-raised-button (click)="onClose()" aria-label="Click here to close form"
        i18n-aria-label>Cancel</button>
</footer>